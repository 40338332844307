<template>
    <Dialog header="Szkła do lusterek" v-model:visible="display" :dismissableMask="true" :draggable="false" :modal="true" :maximizable="true" :style="{width: '90vw'}">
        <div>
            <DataTable :value="table_data">
                <Column header="Rodzaj produktu">
                    <template #body>
                    <img src="@/assets/lusterka-miniatura.jpg" style="vertical-align: middle;width:40px;height:32px;"/>
                    </template>
                </Column>
                <Column field="code_left" header="Kod szkła lewego"></Column>
                <Column field="code_right" header="Kod szkła prawego"></Column>
                <Column field="name" header="Model samochodu"></Column>
                <Column field="date" header="Rocznik"></Column>
            </DataTable>
        </div>
    </Dialog>
    <div class="catalog-glasses-container">
        <div class="card">
            <DataView :value="data" :layout="layout" :paginator="true" :rows="20">
                <template #header>
                    <div class="grid grid-nogutter">
                        <div class="col-lg-6 col-md-12" style="text-align: left">
                            Poniższy katalog przedstawia naszą ofertę szkieł. Jeżeli twoja marka samochodu znajduje sie na liście oznacza to że posiadamy do niej szkła <b> białe </b> i <b> niebieskie </b> w wersji <b> płaskiej </b>, <b> sferycznej </b> oraz <b> asferycznej </b> . Jeżeli twój samochód nie znajduję się na liście zapraszamy do kontaktu, wykonujemy <b> zamówienia indywidualne </b>.
                        </div>
                    </div>
                </template>

                <template #list="slotProps">
                    <div class="col-12">
                        <div class="product-list-item">
                            <img :src="slotProps.data.icon !== 'something' ? require(`@/assets/car_brand/${slotProps.data.icon}`) : null" alt=""/>
                            <div class="product-list-detail">
                                <div class="product-name">{{slotProps.data.name}}</div>
                            </div>
                            <div class="product-list-action">
                                <Button @click="toggleDialog(slotProps.data.name)" class="catalog-button" icon="pi pi-search"></Button>
                            </div>
                        </div>
                    </div>
                </template>

                <template #grid="slotProps">
                    <div :class="width < 1300 ? 'col-4' : 'col-3'">                 
                        <div class="product-grid-item product-card" style="height: 18rem;">
                            <div class="product-grid-item-content">
                                <img :src="slotProps.data.icon !== 'something' ? require(`@/assets/car_brand/${slotProps.data.icon}`) : null" alt=""/>
                                
                            </div>
                            <Divider align="center" type="dashed">
                                <div class="product-name">{{slotProps.data.name}} </div>
                            </Divider>
                            <div class="center">
                                <Button @click="toggleDialog(slotProps.data.name)" class="catalog-button" icon="pi pi-search"></Button>
                            </div>
                        </div>
                    </div>
                </template>
            </DataView>
        </div>   
    </div>
</template>

<script>
    import DataView from 'primevue/dataview';
    import Button from 'primevue/button'
    import Divider from 'primevue/divider'
    import Dialog from 'primevue/dialog';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column'; 
    // import JsonData from '@/assets/json_files/json_data.json'
    export default {
        components: {
            DataView,
            Button,
            Divider,
            Dialog,
            DataTable,
            Column,
        },
        data() {
            return { 
                display: false,
                width: window.innerWidth,
                layout: 'grid',
                sortKey: null,
                sortOrder: null,
                sortField: null,
                sortOptions: [
                    {label: 'Price High to Low', value: '!price'},
                    {label: 'Price Low to High', value: 'price'},
                ],          
                data: [
                        { "name": "Samochody dostawcze", "icon": "delivery1.png" },
                        { "name": "Panoramy dolne", "icon": "delivery1.png" },
                        { "name": "Motocykle", "icon": "motorbike.png" },
                        { "name": "Alfa Romeo", "icon": "icons8-alfa-romeo.svg" },
                        { "name": "Audi", "icon": "icons8-audi.svg" },
                        { "name": "BMW", "icon": "icons8-bmw.svg" },
                        { "name": "Buick", "icon": "icons8-buick.svg" },
                        { "name": "Cadillac", "icon": "icons8-cadillac.svg" },
                        { "name": "Chevrolet", "icon": "icons8-chevrolet.svg" },
                        { "name": "Chrysler", "icon": "icons8-chrysler.svg" },
                        { "name": "Citroen", "icon": "icons8-citroen.png" },
                        { "name": "Dacia", "icon": "dacia_logo.svg" },
                        { "name": "Daewoo", "icon": "daweo.png" },
                        { "name": "Daihatsu", "icon": "icons8-daihatsu-motor-oldest-surviving-japanese-internal-combustion-engine-manufacturers-96.png"},
                        { "name": "Dodge", "icon": "dodge.svg" },
                        { "name": "Ferrari", "icon": "icons8-ferrari.svg" },
                        { "name": "Fiat", "icon": "icons8-fiat.png" },
                        { "name": "Ford", "icon": "icons8-ford.svg" },
                        { "name": "GMC", "icon": "icons8-gmc.svg" },
                        { "name": "Hummer", "icon": "hummer.svg" },
                        { "name": "Honda", "icon": "icons8-honda.svg" },
                        { "name": "Hyundai", "icon": "icons8-hyundai.svg" },
                        { "name": "Infiniti", "icon": "icons8-infiniti.svg" },
                        { "name": "Isuzu", "icon": "icons8-isuzu.png" },
                        { "name": "Jaguar", "icon": "icons8-jaguar.svg" },
                        { "name": "Jeep", "icon": "icons8-jeep.svg" },
                        { "name": "Kia", "icon": "icons8-kia.svg" },
                        { "name": "Lambo", "icon": "icons8-lamborghini.svg" },
                        { "name": "Lexus", "icon": "icons8-lexus.svg" },
                        { "name": "Mazda", "icon": "icons8-mazda.svg" },
                        { "name": "Mercedes", "icon": "icons8-mercedes-benz.svg" },
                        { "name": "Mitsubishi", "icon": "icons8-mitsubishi.svg" },
                        { "name": "Nissan", "icon": "icons8-nissan.svg" },
                        { "name": "Opel", "icon": "opel.svg" },
                        { "name": "Peugeot", "icon": "icons8-peugeot.png" },
                        { "name": "Porsche", "icon": "icons8-porsche.png" },
                        { "name": "Rover", "icon": "rover.svg" },
                        { "name": "Renault", "icon": "icons8-renault.png" },
                        { "name": "Skoda", "icon": "icons8-skoda.png" },
                        { "name": "SAAB", "icon": "saab.svg" },
                        { "name": "Seat", "icon": "seat.svg" },
                        { "name": "Subaru", "icon": "icons8-subaru.png" },
                        { "name": "Suzuki", "icon": "icons8-suzuki.svg" },
                        { "name": "Smart", "icon": "smart.svg" },
                        { "name": "Ssangyong", "icon": "ssangyong.svg" },
                        { "name": "Toyota", "icon": "icons8-toyota.svg" },
                        { "name": "Volkswagen", "icon": "icons8-volkswagen.svg" },
                        { "name": "Volvo", "icon": "icons8-volvo.svg" }
                ]
            }
        },
        methods: {
            onSortChange(event){
                const value = event.value.value;
                const sortValue = event.value;

                if (value.indexOf('!') === 0) {
                    this.sortOrder = -1;
                    this.sortField = value.substring(1, value.length);
                    this.sortKey = sortValue;
                }
                else {
                    this.sortOrder = 1;
                    this.sortField = value;
                    this.sortKey = sortValue;
                }
            },
            getResolution() {
                this.width = window.innerWidth
                if (window.innerWidth < 900) {
                    this.layout = 'list'
                }
                else {
                    this.layout = 'grid'
                }
            },
            toggleDialog(name) {
                const data = require(`@/assets/glasses-json_files/${name}_json_data.json`)
                this.table_data = data
                this.display = true;
            },
            searchAllegro(id) {
                window.open("https://allegro.pl/listing?string=szkło%20" + id, '_blank');
            }
        },

        mounted() {
            this.getResolution()
            window.addEventListener('resize', () => {
                this.getResolution()
            })
        }
    }
</script>

<style scoped>
.catalog-glasses-container {
    height: 100%;
    background-color: rgb(241, 240, 240);
}
.card {
    background: #fff;
    padding: 2rem;
    
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
	border-radius: 4px;
}
.product-card {
    padding-bottom: 0.5rem;
}
 .p-dropdown {
	width: 14rem;
	font-weight: normal;
}
 .product-name {
	font-size: 1.5rem;
	font-weight: 700;
}
 .product-description {
	margin: 0 0 1rem 0;
}
 .product-category-icon {
	vertical-align: middle;
	margin-right: 0.5rem;
}
 .product-category {
	font-weight: 600;
	vertical-align: middle;
}
 ::v-deep(.product-list-item) {
	display: flex;
	align-items: center;
	padding: 1rem;
}
 ::v-deep(.product-list-item) img {
	width: 50px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin-right: 2rem;
}
 ::v-deep(.product-list-item) .product-list-detail {
	flex: 1 1 0;
}
 ::v-deep(.product-list-item) .p-rating {
	margin: 0 0 0.5rem 0;
}
 ::v-deep(.product-list-item) .product-price {
	font-size: 1.5rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
	align-self: flex-end;
}
 ::v-deep(.product-list-item) .product-list-action {
	display: flex;
	flex-direction: column;
}
 ::v-deep(.product-list-item) .p-button {
	margin-bottom: 0.5rem;
}
 ::v-deep(.product-grid-item) {
	margin: 0.5rem;
	border: 1px solid var(--surface-border);
}
 ::v-deep(.product-grid-item) .product-grid-item-top, ::v-deep(.product-grid-item) .product-grid-item-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
 ::v-deep(.product-grid-item) img {
    width: 100px;
    height: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	margin: 2rem 0;
}
 ::v-deep(.product-grid-item) .product-grid-item-content {
	text-align: center;
}
 ::v-deep(.product-grid-item) .product-price {
	font-size: 1.5rem;
	font-weight: 600;
}
 @media screen and (max-width: 576px) {
	.product-list-item {
		flex-direction: column;
		align-items: center;
	}
	.product-list-item img {
		margin: 2rem 0;
	}
	.product-list-item .product-list-detail {
		text-align: center;
	}
	.product-list-item .product-price {
		align-self: center;
	}
	.product-list-item .product-list-action {
		display: flex;
        justify-content: center !important;
		flex-direction: column;
	}
	.product-list-item .product-list-action {
		margin-top: 2rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}
}
.center {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}
.header {
    width: 100%;
    min-height: 2rem;
    padding-top: 1rem !important;
    background-color: rgb(153, 153, 153);
}
.catalog-button {
    background-color: var(--orange10);
    border-color: var(--orange10);
}
.catalog-button:hover {
    background-color: var(--orange20);
    border-color: var(--orange20);
}
.buttons-wrapper {
    display: flex;
    flex-direction: row;
}
.buttons-wrapper > * {
    margin: 0.5rem;
}
.buy-button {
    width: 14rem;
    height: 2.5rem;
}

</style>